export const ROUTES = Object.freeze({
    HOMEPAGE: '/',
    LOGIN: '/login',
    REGISTER: '/register',
    TERMS_AND_CONDITIONS: '/terms-conditions',
    RECOVER_PASSWORD: '/password/recover',
    RESET_PASSWORD: '/password/reset',
    REQUEST_OTP: '/otp/request',
    ENTER_OTP: '/otp/enter',
    // Dashboard Screens
    DASHBOARD: '/overview',
    ENCOUNTERS: '/encounters',
    PATIENT_ENCOUNTERS: '/encounters/:patientId',
    USERS: '/users',
    TRANSACTIONS: '/wallet',
    REVIEWS_RATINGS: '/reviews',
    MANAGE_ACCOUNT: '/manage-account',
    PROFILE: '/profile',
    FORMS: '/forms',
    LAB_INVESTIGATIONS: '/service-requests',
});