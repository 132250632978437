import { Link, useLocation } from 'react-router-dom';
import { SidebarMenuList, SidebarMenuListItem, SidebarWrapper } from './styles';
// import { ReactComponent as PracticeIcon } from "../../assets/icons/practice.svg";
// import { ReactComponent as EncounterIcon } from "../../assets/icons/encounters.svg";
// import { ReactComponent as UsersIcon } from "../../assets/icons/users.svg";
import { ReactComponent as OverviewIcon } from "../../assets/icons/dashboard.svg";
import { ReactComponent as TransactionsIcon } from "../../assets/icons/transactions.svg";
// import { ReactComponent as ReviewsIcon } from "../../assets/icons/reviews.svg";

import { ReactComponent as PracticeIcon } from "../../assets/icons/ui_settings.svg";
import { ReactComponent as LabIcon } from "../../assets/icons/settings.svg";
// import { ReactComponent as FormsIcon } from "../../assets/icons/form.svg";

import { ReactComponent as WalletIcon } from "../../assets/icons/wallet.svg";
import { ReactComponent as UsersIcon } from "../../assets/icons/avatar.svg";
import { ReactComponent as ReviewsIcon } from "../../assets/icons/feedback.svg";
import { ReactComponent as FormsIcon } from "../../assets/icons/bullet-form.svg";
import { ReactComponent as EncounterIcon } from "../../assets/icons/doctor.svg";
import { ReactComponent as ServiceIcon } from "../../assets/icons/healthcare.svg";

const Sidebar = ({dashboardMode, setIsDashboardModeModalVisible}: any) => {
    const location = useLocation();
    const isActivePath = (path: string) => location.pathname.includes(path);
    
    const sidebarMenu = [
        {
            title: "Overview",
            route: "/overview",
            mode: ["integration", "connect"],
            icon: <OverviewIcon fill={isActivePath('/overview') ? "#0f9af0" : "#002e46"} />
        },
        {
            title: "Users",
            route: "/users",
            mode: ["integration", "connect"],
            icon: <UsersIcon fill={isActivePath('/users') ? "#0f9af0" : "#002e46"} />
        },
        {
            title: "Encounters",
            route: "/encounters",
            mode: ["integration"],
            icon: <EncounterIcon fill={isActivePath('/encounters') ? "#0f9af0" : "#002e46"} />
        },
        {
            title: "Consult Forms",
            route: "/forms",
            mode: ["integration"],
            icon: <FormsIcon fill={isActivePath('/forms') ? "#0f9af0" : "#002e46"} />
        },
        {
            title: "Service Requests",
            route: "/service-requests",
            mode: ["integration", "connect"],
            icon: <ServiceIcon fill={isActivePath('/service-requests') ? "#0f9af0" : "#002e46"} />
        },
        {
            title: "Wallet",
            route: "/wallet",
            mode: ["integration", "connect"],
            icon: <WalletIcon fill={isActivePath('/wallet') ? "#0f9af0" : "#002e46"} />
        },
        {
            title: "Reviews & Ratings",
            route: "/reviews",
            mode: ["integration"],
            icon: <ReviewsIcon fill={isActivePath('/reviews') ? "#0f9af0" : "#002e46"} />
        },
        {
            title: "Manage Account",
            route: "/manage-account",
            mode: ["integration", "connect"],
            icon: <PracticeIcon fill={isActivePath('/manage-account') ? "#0f9af0" : "#002e46"} />
        }
    ]
    return (
        <SidebarWrapper>
            <SidebarMenuList>
                {
                    sidebarMenu.filter(({ mode }) => mode.includes(dashboardMode)).map((item, index) => {
                        const { title, route, icon } = item;
                        const active = isActivePath(route);
                        return (
                            <SidebarMenuListItem active={active} key={index}>
                                <Link to={route}>
                                    <span className="icon">{icon}</span>
                                    {title}
                                </Link>
                            </SidebarMenuListItem>
                        )
                    })
                }
            </SidebarMenuList>
            
            <span className="dashboard-mode-switch" onClick={() => setIsDashboardModeModalVisible(true)}>
                <span style={{color: '#333'}}>Dashboard mode: <span style={{textTransform: 'capitalize'}}>{dashboardMode}</span></span>
                <p>
                    <i className="lni lni-shuffle"></i>
                    Switch
                </p>
            </span>
        </SidebarWrapper>
    )
}

export default Sidebar;